<template>
  <section>
    <Header />
    <section class="bg-secondary pt-20">
      <section class="bg-primary-dark">
        <h1 class="container mx-auto text-primary px-10 pb-10 pt-10 text-4xl">
          {{ $t("impact.t-1") }}
        </h1>
        <div class="container mx-auto flex flex-wrap bg-white rounded-2xl">
          <div class="max-w-3xl">
            <div class="p-10">
              <p>{{ $t("impact.p-1") }}</p>
              <h2 class="my-4 font-bold text-2xl text-secondary-rose">{{ $t("impact.t-2") }}</h2>
              <p>{{ $t("impact.p-2") }}</p>
              <p>{{ $t("impact.p-3") }}</p>
              <h2 class="my-4 font-bold text-2xl text-secondary-rose">{{ $t("impact.t-3") }}</h2>
              <p>{{ $t("impact.p-4") }}</p>
            </div>
          </div>
          <div class="mt-10 w-full rounded-t-3xl bg-primary-dark p-10 text-white">
            <h1 class="mb-3 mt-10 text-left text-4xl text-primary">{{ $t("impact.ol-title") }}</h1>
            <div class="flex flex-wrap">
              <div class="w-full flex lg:flex-row flex-wrap flex-col-reverse pr-4 mb-20 lg:mb-0">
                <div class="text-left items-center justify-center flex w-full lg:w-1/2">
                  <div>
                    <h2
                      class="
                        lg:text-3xl
                        text-[2.5rem] text-center
                        lg:text-left
                        font-bold
                        mb-4
                        text-secondary-gold
                      "
                    >
                      {{ $t("impact.ol-1.title") }}
                    </h2>
                    <ol class="text-2xl text-primary">
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            absolute
                            left-0
                            top-0
                            h-8
                            border-secondary-gold border-2
                            font-bold
                            text-secondary-gold text-xl
                          "
                          >1</span
                        >{{ $t("impact.ol-1.li-1") }}
                      </li>
                      <li class="mb-3 relative pl-11">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-gold border-2
                            font-bold
                            text-secondary-gold text-xl
                          "
                          >2</span
                        >{{ $t("impact.ol-1.li-2") }}
                      </li>
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-gold border-2
                            font-bold
                            text-secondary-gold text-xl
                          "
                          >3</span
                        >{{ $t("impact.ol-1.li-3") }}
                      </li>
                    </ol>
                  </div>
                </div>
                <div class="w-full lg:w-1/2">
                  <img
                    class="lg:max-w-auto mx-auto max-w-full md:max-w-md"
                    src="/img/nanac-img-de-consument.png"
                    alt="Consumer"
                  />
                </div>
              </div>
              <div class="w-full flex flex-wrap pl-4 mb-20 lg:mb-0">
                <div class="lg:w-1/2 w-full">
                  <img
                    class="lg:max-w-auto mx-auto max-w-full md:max-w-md"
                    src="/img/nanac-img-de-handelaars.png"
                    alt=""
                  />
                </div>
                <div class="text-left flex items-center justify-center w-full lg:w-1/2">
                  <div>
                    <h2
                      class="
                        lg:text-4xl
                        text-[2.5rem]
                        font-semibold
                        mb-4
                        text-secondary-rose text-left
                        lg:text-right
                      "
                    >
                      {{ $t("impact.ol-2.title") }}
                    </h2>
                    <ol class="text-2xl text-primary">
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-rose border-2
                            font-bold
                            text-secondary-rose text-xl
                          "
                          >1</span
                        >{{ $t("impact.ol-2.li-1") }}
                      </li>
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-rose border-2
                            font-bold
                            text-secondary-rose text-xl
                          "
                          >2</span
                        >{{ $t("impact.ol-2.li-2") }}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="w-full flex lg:flex-row pr-4 flex-wrap flex-col-reverse mb-20 lg:mb-0">
                <div class="text-left w-full lg:w-1/2 flex items-center justify-center">
                  <div>
                    <h2
                      class="
                        lg:text-4xl
                        text-[2.5rem] text-center
                        lg:text-left
                        font-semibold
                        mb-4
                        text-secondary-oliva
                      "
                    >
                      {{ $t("impact.ol-3.title") }}
                    </h2>
                    <ol class="text-2xl text-primary">
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-oliva border-2
                            font-bold
                            text-secondary-oliva text-xl
                          "
                          >1</span
                        >{{ $t("impact.ol-3.li-1") }}
                      </li>
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-oliva border-2
                            font-bold
                            text-secondary-oliva text-xl
                          "
                          >2</span
                        >{{ $t("impact.ol-3.li-2") }}
                      </li>
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-oliva border-2
                            font-bold
                            text-secondary-oliva text-xl
                          "
                          >3</span
                        >{{ $t("impact.ol-3.li-3") }}
                      </li>
                    </ol>
                  </div>
                </div>
                <div class="w-full lg:w-1/2">
                  <img
                    class="lg:max-w-auto mx-auto max-w-full md:max-w-md"
                    src="/img/nanac-img-de-economie.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full flex flex-wrap pl-4 mb-20 lg:mb-0">
                <div class="w-full lg:w-1/2">
                  <img
                    class="max-w-full md:max-w-md lg:max-w-auto mx-auto"
                    src="/img/nanac-img-de-overheid.png"
                    alt=""
                  />
                </div>
                <div class="text-left w-full lg:w-1/2 flex items-center justify-center">
                  <div>
                    <h2
                      class="
                        lg:text-4xl
                        text-[2.5rem]
                        font-semibold
                        text-left
                        lg:text-right
                        mb-4
                        text-secondary-rose
                      "
                    >
                      {{ $t("impact.ol-4.title") }}
                    </h2>
                    <ol class="text-2xl text-primary">
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-rose border-2
                            font-bold
                            text-secondary-rose text-xl
                          "
                          >1</span
                        >{{ $t("impact.ol-4.li-1") }}
                      </li>
                      <li class="mb-3 relative pl-11 flex items-center">
                        <span
                          class="
                            mr-3
                            mt-1
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            w-8
                            h-8
                            absolute
                            left-0
                            top-0
                            border-secondary-rose border-2
                            font-bold
                            text-secondary-rose text-xl
                          "
                          >2</span
                        >{{ $t("impact.ol-4.li-2") }}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container flex flex-wrap bg-white rounded-2xl mx-auto">
          <div class="p-10">
            <div class="max-w-3xl">
              <h1 class="text-3xl mb-5">{{ $t("about.title") }}</h1>
              <p>
                {{ $t("about.p-1") }}
              </p>
              <p>
                {{ $t("about.p-2") }}
              </p>

              <hr class="border-primary-dark max-w-lg my-10" />
              <h1 class="text-3xl mb-5">{{ $t("about.t-1") }}</h1>
            </div>
            <div class="flex flex-wrap font-semibold">
              <div class="w-full lg:w-1/4 mb-4 lg:mb-0 lg:pr-2">
                <div class="p-4 bg-secondary-citrus h-full">
                  <p>
                    {{ $t("about.li-1") }}
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/4 mb-4 lg:mb-0 lg:px-2">
                <div class="p-4 bg-secondary-gold h-full">
                  <p>
                    {{ $t("about.li-2") }}
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/4 mb-4 lg:mb-0 lg:px-2">
                <div class="p-4 bg-secondary-oliva h-full">
                  <p>
                    {{ $t("about.li-3") }}
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/4 lg:pl-2">
                <div class="p-4 bg-secondary-rose h-full">
                  <p>
                    {{ $t("about.li-4") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              bg-primary-dark
              w-fullF
              rounded-t-3xl
              p-10
              border-b-2 border-white border-opacity-20
            "
          >
            <h1 class="text-white text-3xl mb-10">{{ $t("about.t-2") }}</h1>
            <div class="flex flex-wrap">
              <div class="text-primary w-full lg:w-1/3 flex flex-wrap lg:block lg:pr-2">
                <div
                  class="h-72 w-full sm:w-1/2 lg:w-auto lg:h-[400px]"
                  :style="{
                    background: 'url(/img/bestuur-nanac-kristian-vanderwaeren.jpg)',
                    backgroundSize: 'cover',
                  }"
                ></div>
                <div
                  class="w-full sm:w-1/2 lg:w-full flex flex-col justify-end lg:block p-3 text-xl"
                >
                  <h2 class="font-bold block">{{ $t("about.st-1") }}</h2>
                  <span class="block">{{ $t("about.name-1") }}</span>
                  <span>{{ $t("about.role-1") }}</span>
                </div>
              </div>
              <div
                class="
                  text-primary
                  w-full
                  my-16
                  lg:my-0 lg:w-1/3 lg:px-2
                  flex flex-wrap flex-row-reverse
                  lg:block
                "
              >
                <div
                  class="h-72 w-full sm:w-1/2 lg:w-auto lg:h-[400px]"
                  :style="{
                    background: 'url(/img/bestuur-nanac-julie-dere.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }"
                ></div>

                <div
                  class="w-full sm:w-1/2 lg:w-full flex flex-col justify-end lg:block p-3 text-xl"
                >
                  <h2 class="font-bold block">{{ $t("about.st-2") }}</h2>
                  <span class="block">{{ $t("about.name-2") }}</span>
                  <span>{{ $t("about.role-2") }}</span>
                </div>
              </div>
              <div class="text-primary w-full lg:w-1/3 lg:pr-2 flex flex-wrap lg:pl-2 lg:block">
                <div
                  class="h-72 w-full sm:w-1/2 lg:w-auto lg:h-[400px]"
                  :style="{
                    background: 'url(/img/bestuur-nanac-roland-de-meersman.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }"
                ></div>
                <div
                  class="w-full sm:w-1/2 lg:w-full flex flex-col justify-end lg:block p-3 text-xl"
                >
                  <h2 class="font-bold block">{{ $t("about.st-3") }}</h2>
                  <span class="block">{{ $t("about.name-3") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <Footer />
  </section>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
};
</script>
